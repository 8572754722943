import React from "react";
import { Typography, 
  makeStyles, 
  createStyles,  } from '@material-ui/core';

const muiStyles = makeStyles(() => {
  return createStyles({
    footerWrapper: {
      backgroundColor: '#333333',
      color: '#FFFFFF',
      padding: '20px',
      textAlign: 'center',
    },
    copyright: {
      lineHeight: '1.375rem',
      fontSize: '0.8669rem',
    }
  });
});

const Footer = ({ children }) => {
  const classes = muiStyles();

  return (
    <footer className={classes.footerWrapper}>
      <Typography variant="h6" align="center" gutterBottom className={classes.copyright}>
      Impactful Information
      </Typography>
      <span>
      2001 Timberloch Place, Ste 500, #401, The Woodlands, TX 77380
      </span>
  </footer>
  )
};

export default Footer;
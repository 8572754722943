import React from "react";
import { CssBaseline } from "@material-ui/core";
import Footer from "./footer";
import favicon from "../images/static/favicon.ico";
import { Helmet } from "react-helmet";


const Layout = ({ children }) => {
  return (
   <React.Fragment>
      <CssBaseline />
      <Helmet>
        <link rel="icon" href={favicon} />
      </Helmet>
      <main>
        {children}
      </main>
      <Footer />
   </React.Fragment>
  )
};

export default Layout;
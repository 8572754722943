import API from "./API";

export const zippo = async (value) => {
  try {
    const zip = await API.getZipcode(value);
    if (zip) {
      const place = zip.data.places[0];
      if (place) {
        return {
          city: place["place name"],
          state: place["state abbreviation"],
          zipcode: zip.data["post code"],
        };
      } else {
        return false;
      }
    }
  } catch (error) {
    return false;
  }
};

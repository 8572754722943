import React, { useState, useEffect } from "react";
import { navigate } from "gatsby";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { zippo } from "../utils/utilityFunctions";
import Layout from "../components/Layout";
import {  Container, 
          Grid, 
          makeStyles, 
          createStyles, 
          Typography,
          option,
          FormHelperText,
          FormControl,
          Select,
          TextField,
          Button,
          Modal,
          Link,
          }  from "@material-ui/core/";
import CancelIcon from '@material-ui/icons/Cancel';
import { StaticImage } from "gatsby-plugin-image";

import ScriptEditorImage from "../images/notes.svg";
import PhotoGalleryImage from "../images/images.svg";
import LoginImage from "../images/key.svg";

const muiStyles = makeStyles((theme) => {

  const inputTheme = {
    backgroundColor: "#2A2A2A",
    color: "#ffffff",
    borderRadius: "4px",
    border: "1px solid #ffffff",
  };

  const headingTheme = {
    color: "#1f3864",
    fontFamily: 'Arial',
    fontWeight: '700',
  }

  return createStyles({
    imageWrapper: {
      display: 'grid',
    },
    heroImage: {
      gridArea: '1/1',
      maxHeight: '550px',
    },
    heroOverlay: {
      opacity: '0.41',
      backgroundColor: '#FFFFFF',
      gridArea: '1/1',
    },
    heroContentWrapper: {
      gridArea: "1/1",
      position: "relative",
      placeItems: "center",
      display: "grid",
    },
    heroContent: {
      height: "100%",
    },
    heroHeadline:{
      ...headingTheme,
      textAlign: 'center',
      lineHeight: '2.875rem',
      fontSize: '2.3529rem',
    },
    heroSubHeader: {
      color: "#000000",
      textAlign: 'center',
      lineHeight: '1.1875rem',
      fontSize: '0.9907rem',
    },
    getQuoteForm: {
      textAlign: 'center',
    },
    getQuoteFormSelect: {
      ...inputTheme,
      '&& option, && optgroup': {
        ...inputTheme,
      },
      '&& .MuiSelect-icon': {
        color: '#FFFFFF',
      }
    },
    getQuoteZipcode:{
      ...inputTheme,
    },
    getQuoteFormSubmit:{
      padding: '15px 32px',
      backgroundColor: '#0e4d16',
      borderRadius: 0,
      fontWeight: 700,
      '&&:hover': {
        backgroundColor: '#12631c',
      }
    },
    inputError: {
      color: '#FFFFFF',
    },
    tipsContainer: {
      textAlign: 'center',
      padding: '30px 45px',
    },
    tipsHeading:{
      ...headingTheme,
      lineHeight: '2.375rem',
      fontSize: '1.4861rem',
    },
    p: {
      lineHeight: '1.625rem',
      fontSize: '0.9907rem',
    },
    articleImage: {
      gridArea: '1/1',
      height: '175px',
    },
    articleTitle: {
      lineHeight: '1.8125rem',
      fontSize: '1.1146rem',
      paddingTop: '20px',
      paddingBottom: '20px',
      textAlign: 'left',
    },
    articleExcerpt: {
      lineHeight: '1.625rem',
      fontSize: '0.9907rem',
      textAlign: 'left',
    },
    articleReadMore: {
      lineHeight: '1.625rem',
      fontSize: '0.9907rem',
      color: '#1c6bbd',
      textAlign: 'justify',
      cursor: 'pointer',
    },
    stepsHeading:{
      ...headingTheme,
      lineHeight: '2.875rem',
      fontSize: '2.3529rem',
    },
    stepTitle:{
      ...headingTheme,
      lineHeight: '1.5625rem',
      fontSize: '1.1146rem',
      marginTop: '20px',
    },
    stepText:{
      lineHeight: '1.25rem',
      fontSize: '0.8669rem',
    },
    stepCircle: {
      border: 0,
      background: 'rgb(240,243,245)',
      borderRadius: '50%',
      width: '150px',
      height: '150px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      margin: 'auto',
    },
    stepIcon: {
      height: '3.8125rem',
      width: '3.8125rem',
    },
    modal: {
      overflowY: 'scroll',
      padding:"50px",
      backgroundColor: 'rgba(0,0,0,0.65)',
      [theme.breakpoints.down('sm')]: {
        padding:"0px",
      },
    },
    modalWrapper: {
      padding:"50px",
      outline: 'none',
      [theme.breakpoints.down('sm')]: {
        padding:"20px",
      },
    },
    modalCloseIcon: {
      position: 'absolute',
      top: '-20px',
      right: '-5px',
      fontSize: '2.5em',
      border: '1px solid #FFFFFF',
      borderRadius: '50%',
      backgroundColor: '#FFFFFF',
    },
    modalContent: {
      backgroundColor: '#ffffff',
      border: '0px solid #000',
      padding: '20px',
      textAlign: 'left',
      lineHeight: '1.8125rem',
      fontSize: '1.1146rem',
      color: '#37465A',
      borderRadius: '10px',
      position: 'relative',
      outline: 'none',
    },
    footerGetQuote: {
      paddingTop: '50px',
      paddingBottom: '50px',
    },
    footerGetQuoteInputError: {
      color: 'red',
    }
  });
});

// yup validation schema -- overkill but easy to use
const schema = yup.object().shape({
  zipcode: yup
    .string()
    .min(5, "Zipcode must be 5 characters")
    .max(5, "Zipcode must be 5 characters")
    .matches(/(^\d{5}$)/, "Please enter a valid zipcode")
    .required("Zip Code is required"),
  vertical: yup.string().required("This field is required"),
});

const GetQuoteForm = ({className, inputErrorClassName, inputLayout = "default"}) => {
  const [validZip, setValidZip] = useState();
  const [zippoResponse, setZippoResponse] = useState();

  const classes = muiStyles();

  // react-hook-form useForm Hook
  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    if (validZip) {
      const { city, state } = zippoResponse;

      // performs the redirect via Gatsby's navigate method.  We pass the state to be stored in sessionStorage on the TYP
      navigate("/thankyou/", { state: { ...data, city, state } });
    }
  };

  const handleChange = (e) => {
    const { value } = e.target;
    const noString = value.replace(/\D/g, "");

    // prevents strings in this input
    setValue("zipcode", noString, {
      shouldValidate: true,
      shouldDirty: true,
    });    

    if (value && value.length === 5) {

      // calls the zippo API to get the city/state which gets stored in sessionStorage
      zippo(value).then((res) => {
        if (res) {
          clearErrors("zipcode");

          // zipcode input is not a controlled input so we ref the DOM to remove the class. 
          document.getElementById("zipcode").classList.remove("border-error");
          setZippoResponse(res);
          setValidZip(true);
        } else {
          setValidZip(false);
        }
      });
    }
  };

  useEffect(() => {
    // can't be a falsy check here need to check for explicit false value 
    if (validZip === false) {
      setError("zipcode", {
        type: "manual",
        message: "Please enter a valid zipcode.",
      });
    }
  }, [validZip, setError]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={className}>
      <Grid container justify={inputLayout === "inline" ? "center" : "left"}>
        <Grid container item xs={12} sm={inputLayout === "inline" ? 4 : 7} justify="flex-end">
          <Grid item xs={12} sm={inputLayout === "inline" ? 11 : 9} >
            <FormControl variant="outlined" fullWidth>
              <Select
                id="quoteType"
                displayEmpty
                defaultValue=""
                native
                className={classes.getQuoteFormSelect}
                {...register("vertical")}
              >
                <option value="" disabled>
                  What are you looking for?
                </option>
                <option value="refinance">Refinance</option>
                <option value="purchase">Home Mortgage</option>
                <option value="dpa">Down Payment Assistance (DPA)</option>
                <option value="rto">Rent to Own</option>
                <optgroup label="Insurance">
                  <option value="auto">Auto Insurance</option>
                  <option value="home">Home Insurance</option>
                  <option value="life">Life Insurance</option>
                  <option value="medsup">Medicare Support</option>
                </optgroup>
                <option value="solar">Solar</option>
                <option value="windows">Windows</option>
                <option value="roofing">Roofing</option>
              </Select>
              <FormHelperText className={inputErrorClassName || classes.inputError}>{errors.vertical?.message || " "}</FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container item xs={12} sm={inputLayout === "inline" ? 4 : 7} justify="flex-end">
          <Grid item xs={12} sm={inputLayout === "inline" ? 11 : 9} >
            <FormControl variant="outlined" fullWidth>
              <TextField
                id="zipcode"
                placeholder="Zip Code"
                name="zipcode"
                variant="outlined"
                {...register("zipcode")}
                onChange={handleChange}
                InputProps={{
                  className: classes.getQuoteZipcode,
                }}
                autoComplete="off"
                value={getValues("zipcode")}
              />
              <FormHelperText className={inputErrorClassName ||  classes.inputError}>{errors.zipcode?.message || " "}</FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container item xs={12} sm={inputLayout === "inline" ? 4 : 5} justify={inputLayout === "inline" ? "flex-end" : "flex-start"}>
          <Grid item xs={12} sm={inputLayout === "inline" ? 11 : 9} md={inputLayout === "inline" ? 11 : 10} >
            <Button variant="contained" color="primary" type="submit" className={classes.getQuoteFormSubmit} fullWidth={inputLayout === "inline" ? true : false}>
              Get Quote
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

const Hero = () => {
  const classes = muiStyles();
  return (
    <div className={classes.imageWrapper}>
      <StaticImage
        className={classes.heroImage}
        layout="fullWidth"
        alt="Confirm Your Savings"
        src={
          "../images/confirmyoursavings.com_Header.jpg"
        }
        formats={["auto", "webp", "avif"]}
      />
      <div className={classes.heroOverlay}/>
      <Container className={classes.heroContentWrapper} maxWidth="md">
        <Grid container direction="row"
          justify="flex-start"
          alignItems="center" 
          className={classes.heroContent}>
          <Grid item xs={12} sm={12} md={8} lg={7}>
            <br/>
            <Typography variant="h1" component="h1" className={classes.heroHeadline}>
              Confirm Your Savings
            </Typography>
            <p className={classes.heroSubHeader}>
              <strong>
                Are you trying to save on your home and insurance needs? We’ve got you covered. Let us know where you live and we will connect you with local professionals.
              </strong>
            </p>
            <br/><br/>
            <GetQuoteForm className={classes.getQuoteForm}/>
            <br/>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

const Tips = () => {
  const classes = muiStyles();

  return (
    <div style={{backgroundColor: '#fbfbfb',}}>
      <Container className={classes.tipsContainer} maxWidth="md">
        <Typography variant="h5" component="h5" className={classes.tipsHeading}>
          Your Source For Saving
        </Typography>
        <p className={classes.p}>
          Looking for ways to reduce your insurance premiums? Are you in the market for a new home, an upgrade or refi? We connect you with local professionals that can help you keep more of your hard-earned cash in your pocket. Shop, compare and save!
        </p>
      </Container>
    </div>
  )
};

const Blogs = ( { setModalProps }) => {
  const classes = muiStyles();

  return (
    <div style={{backgroundColor: '#ffffff',}}>
      <Container className={classes.tipsContainer} maxWidth="md">
        <Grid container  spacing={6}>
          <Grid item xs={12} sm={4}>
            <StaticImage
              className={classes.articleImage}
              layout="fullWidth"
              alt="How Does Credit History Affect Your Insurance Premiums?"
              src={"../images/confirmyoursavings.com_Article1.jpg"}
              formats={["auto", "webp", "avif"]}
            />
            <Typography variant="h6" component="h6" className={classes.articleTitle}>
              How Does Credit History Affect Your Insurance Premiums?
            </Typography>
            <p className={classes.articleExcerpt}>
              When you shop for insurance, do you think of how your credit scores might affect your premiums? It’s probably not the first thing you think of because the two do not seem related. They are in a way, ...
            </p>
            <p className={classes.articleReadMore}>
              <Link href="#" onClick={() => setModalProps({
                isOpen: true,
                title: 'How Does Credit History Affect Your Insurance Premiums?',
                description: 'How Does Credit History Affect Your Insurance Premiums?',
                content: (
                  <React.Fragment>
                    <Typography variant="h3" component="h3">How Does Credit History Affect Your Insurance Premiums?</Typography>
                    <p>
                      By JMcHood September 14, 2018
                    </p>
                    <p>
                    When you shop for insurance, do you think of how your credit scores might affect your premiums? It’s probably not the first thing you think of because the two do not seem related. They are in a way, though.
                    </p>
                    <p>
                    Many insurance companies pull your credit because it helps them determine the risk you pose. Insurance companies aren’t worried about you not paying your premiums – they’ll just cancel your policy if you don’t pay. What they are more concerned about is how likely you are to file a claim. If you file a claim, it hurts the insurance company because they have to pay out. If you are a high-risk of filing a claim, you will probably pay higher insurance premiums.
                    </p>
                    <p>
                      <strong>What’s the Role of Your Credit Score?</strong><br/>
                      Insurance companies don’t take your credit score and then determine your premium. Instead, they look at your credit history. They want to know the following:
                      <ul>
                        <li>Your pattern of paying bills – Do you pay your bills on time?</li>
                        <li>How long is your credit history?</li>
                        <li>How much of your available credit do you have outstanding?</li>
                        <li>Do you have collections?</li>
                        <li>Are there a lot of recent inquiries?</li>
                      </ul>
                    </p>
                    <p>
                    The insurance company then uses these factors to determine your riskiness. If you pay your bills late, have a lot of outstanding credit, and have multiple collection accounts, you may be seen as ‘irresponsible.’ This may not affect the way you drive or the way you care for your home, but it’s the feeling the insurance company gets. Chances are that you will pay a higher premium if your situation looks like that.
                    </p>
                    <p>
                    If, on the other hand, you pay your bills on time, you don’t have any collections, you barely charge your credit cards, and you have a long credit history, you may get lower premiums because you look as if you are a lower risk.
                    </p>
                    <p>
                      <strong>Other Factors That Play a Role</strong><br/>
                      Your credit score/credit history aren’t the only factors that play a role here. Insurance companies will also look at your insurance history. Do you file a lot of claims? Do you have a bad driving record? Is your home located in a high-risk area? These factors play a role in how the insurance company determines your premium.
                    </p>
                    <p>
                    The insurance company puts all of the factors together to determine the premiums you will pay. The higher risk you pose for the insurance company, the higher the premiums you will pay. If you are a pretty low-risk type consumer, though, you may enjoy those low premiums.
                    </p>
                    <p>
                    When you apply for insurance, no matter the type, you should give your credit report a once over. Look to see if you have any negative information on there that you can fix. For example, if you have late payments, bring them current. If you overextended your credit, pay some of it off and don’t use your credit cards again.
                    </p>
                    <p>
                    The less risk you show an insurance company, the less they will charge you for premiums. You can then save that money for the deductibles you will face should you have to file a claim in the future.
                    </p>
                  </React.Fragment>
                ),
              })}>READ MORE</Link>
            </p>
          </Grid>
          <Grid item xs={12} sm={4}>
            <StaticImage
              className={classes.articleImage}
              layout="fullWidth"
              alt="How is a Premium Different From a Deductible?"
              src={
                "../images/confirmyoursavings.com_Article2.jpg"
              }
              formats={["auto", "webp", "avif"]}
            />
            <Typography variant="h6" component="h6" className={classes.articleTitle}>
              How is a Premium Different From a Deductible?
            </Typography>
            <p className={classes.articleExcerpt}>
            When you shop for insurance, whether it's medical, homeowners, or auto insurance, you'll hear two different terms thrown around. They both affect how much you pay for insurance, so they are important ...
            </p>
            <p className={classes.articleReadMore}>
              <Link href="#" onClick={() => setModalProps({
                isOpen: true,
                title: 'How is a Premium Different From a Deductible?',
                description: 'How is a Premium Different From a Deductible?',
                content: (
                  <React.Fragment>
                    <Typography variant="h3" component="h3">How is a Premium Different From a Deductible?</Typography>
                    <p>
                      By JMcHood August 6, 2018
                    </p>
                    <p>
                    When you shop for insurance, whether it’s medical, homeowners, or auto insurance, you’ll hear two different terms thrown around. They both affect how much you pay for insurance, so they are important terms to understand. They are premium and deductible.
                    </p>
                    <p>
                      <strong>What is the Insurance Premium?</strong><br/>
                      The insurance premium is what you pay each month for your insurance. Insurance companies determine your premium based on your risk level. The riskier you are, the higher premiums an insurance will charge you. They base their premiums on your likelihood of filing a claim.
                    </p>
                    <p>
                    You have to pay your premium each month, or your insurance could lapse. It’s important to choose a policy that has a premium that you can afford. Remember, you will pay the premiums whether you use the insurance or not.
                    </p>
                    <p>
                      <strong>What is the Deductible?</strong><br/>
                      The deductible is the portion of the claim that you will pay before the insurance will take effect. Let’s say you have auto insurance with a deductible of $1,000. You get into a car accident and need to file a claim. The insurance company will require that you pay the first $1,000 of the damages before they will start paying for the damages.
                    </p>
                    <p>
                    You will find deductibles that range from $100 to several thousand dollars. Typically, insurance companies offer a variety of deductibles to help you choose the program that fits your budget. You should choose your deductible based on what you can afford.
                    </p>
                    <p>
                    For example, if you have the option of a $1,000 or $2,500 deductible, but you don’t have $2,500 in a liquid account, such as checking or savings, you shouldn’t choose the higher deductible. Even though you don’t need to pay the deductible unless you need to make a claim, there’s no guarantee that you won’t have to use the insurance.
                    </p>
                    <p>
                      <strong>How Deductibles and Premiums are Related</strong><br/>
                      While the premium and deductible are two different things, they do coincide. As we said above, you have a choice on your deductible on most insurance programs. The larger deductible that you choose, the lower the premium an insurance company will charge.
                    </p>
                    <p>
                    Insurance companies charge the lower premium because they take a lower risk when you have a larger deductible. Going back to the auto insurance example with the $2,500 deductible. If you were in an accident, the likelihood of the insurance company having to pay anything beyond the $2,500 that you are required to pay is small. In exchange for this low risk, the insurance company will give you a lower premium.
                    </p>
                    <p>
                    Before you choose a premium and deductible, think carefully about what you can afford. The premium is something you will pay on a regular basis, so you should make sure that it fits within your monthly budget. Your deductible is something you will pay should you need to make a claim. You need to decide if you can afford a higher deductible should something happen or if you should take the lower deductible and pay the higher premium instead. The choice is yours and you can always revise your plan should your financial status change, making it easy for you to always stick within your budget.
                    </p>
                  </React.Fragment>
                  )
                  })}
                >READ MORE</Link>
            </p>
          </Grid>
          <Grid item xs={12} sm={4}>
            <StaticImage
              className={classes.articleImage}
              layout="fullWidth"
              alt="Is it Worth it to Bundle Insurance?"
              src={
                "../images/confirmyoursavings.com_Article3.jpg"
              }
              formats={["auto", "webp", "avif"]}
            />
            <Typography variant="h6" component="h6" className={classes.articleTitle}>
            Is it Worth it to Bundle Insurance?
            </Typography>
            <p className={classes.articleExcerpt}>
            Insurance companies typically offer a discount if you bundle your insurance. This means getting multiple policies from the same agent. Typically, insurance companies offer the largest discount for ...
            </p>
            <p className={classes.articleReadMore}>
              <Link onClick={() => setModalProps({
                isOpen: true,
                title: 'Is it Worth it to Bundle Insurance?',
                description: 'Is it Worth it to Bundle Insurance?',
                content: (
                  <React.Fragment>
                    <Typography variant="h3" component="h3">Is it Worth it to Bundle Insurance?</Typography>
                    <p>
                    By JMcHood December 3, 2019
                    </p>
                    <p>
                    Insurance companies typically offer a discount if you bundle your insurance. This means getting multiple policies from the same agent. Typically, insurance companies offer the largest discount for auto and home insurance bundles, but it’s worth asking about other policies if you need them too.
                    </p>
                    <p>
                      <strong>How Much Can you Save?</strong><br/>
                      The big question is how much can you save? While each insurance company differs, on average, expect around a 5 – 25% savings. This is the savings on each policy. If your home insurance would cost $1,000, but you can get a bundling discount, you may save between $50 and $250. If your car insurance would cost $1,500 per year, you’d save $75 – $375.
                    </p>
                    <p>
                    Insurance companies like when you bundle policies because it means they make more money. Typically, insurance companies aren’t interested in writing just one policy. If they do, they often jack the prices up higher, making bundling insurance policies that much more attractive.
                    </p>
                    <p>
                      <strong>How do Insurance Companies Benefit from Bundling Policies?</strong><br/>
                      Here’s the thing. Insurance companies do benefit from bundling policies, but if you are careful, you’ll protect yourself. When you bundle insurance, it’s easy to just leave it. Every year, the policies renew, typically at a slightly higher premium. You may or may not notice. But with multiple policies with the same company, you are likely to leave the policies rather than shop around.
                    </p>
                    <p>
                    Keep in mind, not all insurance companies automatically increase their rates. Just pay close attention at renewal time. Read your renewal documents and determine if your premiums increased. While a slight increase is normal – the cost of living increases every year, a large increase may not be necessary.
                    </p>
                    <p>
                      <strong>How do You Benefit from Bundling Policies?</strong><br/>
                      We already talked about the benefits of saving money. That’s the largest benefit for most consumers. But, what other ways do you benefit?
                      <ul>
                        <li>Convenience – Let’s face it, no one likes to shop for insurance. If you can get multiple policies with one agent, it makes life much easier. If you have questions, you know exactly who to call. You have one online log in and have to spend less time managing your policies.</li>
                        <li>Relationships – Insurance is important as it saves you during some of the most stressful times. Building a relationship with your agent helps make things easier. You can talk through the issues and feel comfortable about doing so.</li>
                        <li>Easier qualifications – Insurance is risky. Insurance companies have to decide if they want to protect you or not. If you bundle insurance policies, companies are more likely to give you the policies you need rather than if you only had one policy.</li>
                      </ul>
                    </p>
                    <p>
                      <strong>What to Look for When Bundling Policies</strong><br/>
                      If you decide to bundle your insurance policies, consider the following:
                      <ul>
                        <li>Check your policies at least every other year. Look at the premiums. How much have they increased? If it’s more than 10%, you may want to shop around.</li>
                        <li>Check your credit. Has your credit score increased since the last time you got insurance? You may want to see if you qualify for lower rates. Some states base insurance rates on credit scores, while others don’t. It’s worth checking.</li>
                        <li>Look for policies three years after receiving a moving violation – Your rates may increase for the first three years following the violation, but at that 3-year mark, you should be able to secure lower rates.</li>
                      </ul>
                    </p>
                    <p>
                    No matter when you are looking for a bundled policy, always read the fine print. Know the coverage and coverage amounts. Make sure they are adequate to meet your needs. Also, look at the savings. Price policies separately and bundled with several agents. This way you’ll know whether or not bundling is a good idea.
                    </p>
                    <p>
                    Bundling insurance should save you money and be more convenient, but each insurance company operates differently. Interview different agents, ask a lot of questions, and know beyond a reasonable doubt that you are getting the coverage you need for any policies included in the bundle.
                    </p>
                  </React.Fragment>
                  )
                  })}
                >READ MORE</Link>
            </p>
          </Grid>
        </Grid>
      </Container>
    </div>
  )
};

const Steps = () => {
  const classes = muiStyles();

  return (
    <div style={{backgroundColor: '#ffffff',}}>
      <Container className={classes.tipsContainer}>
        <Typography variant="h2" component="h2" className={classes.stepsHeading}>
          We Bring The Pros To You In<br/>
          3 Simple Steps
        </Typography>
        <Grid container spacing={10} justify="center" className={classes.tipsContainer}>
          <Grid item xs={12} sm={4} md={3}>
            <div className={classes.stepCircle}>
              <img src={ScriptEditorImage} alt="Fill us in." className={classes.stepIcon} />
            </div>
            <Typography variant="h3" component="h3" className={classes.stepTitle}>
              Fill us in.
            </Typography>
            <p className={classes.stepText}>
              Share some basic info to get customized service.
            </p>
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <div className={classes.stepCircle}>
              <img src={PhotoGalleryImage} alt="Take your pick." className={classes.stepIcon} />
            </div>
            <Typography variant="h3" component="h3" className={classes.stepTitle}>
              Take your pick.
            </Typography>
            <p className={classes.stepText}>
              Compare best estimates from top companies.
            </p>
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <div className={classes.stepCircle}>
              <img src={LoginImage} alt="Ink your deal." className={classes.stepIcon} />
            </div>
            <Typography variant="h3" component="h3" className={classes.stepTitle}>
              Ink your deal.
            </Typography>
            <p className={classes.stepText}>
              Connect with a local agent to get a quote or apply online.
            </p>
          </Grid>
        </Grid>
      </Container>
    </div>
  )
};

const FooterGetQuote = () =>  {
  const classes = muiStyles();

  return (
    <Grid container justify="center" alignItems="center" className={classes.footerGetQuote}>
      <Grid item xs={10} lg={7} xl={5}>
        <GetQuoteForm className={classes.getQuoteForm} inputErrorClassName={classes.footerGetQuoteInputError} inputLayout="inline" />
      </Grid>
    </Grid>
  );
};

const IndexPage = () => {
  const classes = muiStyles();

  const [ modalProps, setModalProps ] = useState({ isOpen: false, title: '', description: '', content: ''});

  const closeModal = () =>{
    setModalProps({ isOpen: false, title: '', description: '', content: ''});
  }

  return (
    <Layout>
      <Hero />
      <Tips />
      <Blogs setModalProps={setModalProps} />
      <Steps />
      <FooterGetQuote />
      <Modal
        open={modalProps.isOpen}
        onClose={closeModal}
        className={classes.modal}
        id="blog-modal"
        disableBackdropClick
      >
        
        <div className={classes.modalWrapper}>
          <Container className={classes.modalContent}>
            <CancelIcon className={classes.modalCloseIcon} onClick={closeModal}/>
              {modalProps.content}
          </Container>
        </div>
      </Modal>
    </Layout>
  )
};

export default IndexPage;
